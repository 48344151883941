<template>
  <div class="center-wrapper">
    <Message v-if="isCheckout" type='alert' @onClick="onCheckout" :message="alertMessage" />
    <div class="tabs-bar">
      <Tabs :items="tabs" />
    </div>
    <div class="cart-wrapper">
      <h3>Cart Info</h3>
      <div class="input-wrapper outer-block">
        <div class="input-container noIcon">
          <label>Order</label>
          <input class="input-field" type="text" v-model="regForm.order" placeholder="Auto Generate" disabled>
        </div>
        <div class="input-container noIcon">
          <label>PO#</label>
          <input class="input-field" type="text" v-model="regForm.po">
           <p v-if="errors.hasOwnProperty('po')" class="error">Field required</p>
        </div>
          <div class="input-container noIcon">
          <label>Ship Date</label>
         <!-- <input class="input-field"  type="text" placeholder="To be provided by splieer(via Mail)">-->
          <date-picker v-model="regForm.shipdate" valueType="format" :disabled-date="disabledBeforeTodayAndAfterAWeek"></date-picker>
           <p v-if="errors.hasOwnProperty('shipdate')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Ship Via</label>
          <!--<input class="input-field" type="text">-->
          <MHSelectbox :options="options" v-model="regForm.shipvia" :selectedOption="shipVia" @selectOption="selectShipVia" :isArray="true" :noBorder="true" />
          <p v-if="errors.hasOwnProperty('shipvia')" class="error">Field required</p>
        </div>
       
      </div>
      <div class="input-wrapper outer-block">
        <div class="input-container noIcon">
          <label>Sold To</label>
          <input class="input-field" type="text" placeholder="No Customer Selected" v-model="regForm.soldTo">
           <p v-if="errors.hasOwnProperty('soldTo')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Dealer's Email</label>
           <input class="input-field" type="text" placeholder="No Customer Selected"  v-model="regForm.dealerEmail">
            <p v-if="errors.hasOwnProperty('dealerEmail')" class="error">Field required</p>
            <p v-else-if="errors.hasOwnProperty('emailAddressRegex')" class="error">Invalid email!</p>
        </div>
        <div class="input-container noIcon" style="visibility:hidden">
        </div>
        <div class="input-container noIcon" v-if="!isHidden">
          <input class="input-field" type="text" v-model="regForm.shipviaPicup"  placeholder="Ship Via" >
          <p v-if="errors.hasOwnProperty('shipviaPicup')" class="error">Field required</p>
        </div>
      </div>
      <div class="input-wrapper outer-block column-2">
        <div class="input-container noIcon">
          <label>Bill To</label>
          <textarea class="input-field" type="text" placeholder="Company address" v-model="regForm.billTo" />
           <p v-if="errors.hasOwnProperty('billTo')" class="error">Field required</p>
        </div>
        <div class="input-container noIcon">
          <label>Ship To</label>
          <textarea class="input-field" type="text" placeholder="Multiple warehouse, Single" v-model="regForm.shipTo" />
           <p v-if="errors.hasOwnProperty('shipTo')" class="error">Field required</p>
        </div>
      </div>
      <div class="cart-items">
        <h3 class="cart-heading">Cart Items</h3>
        <div class="cart-section" v-if="getCartStatus">
          <section>
            <header>
              <div :class="{'col-2': sub === 0}" class="col" v-for="(item, sub) in cartHeaders" :key="sub">
                {{ item }}
              </div>
            </header>
            <div v-if="cartItems && cartItems[0]">
              <div v-for="(cartdetail,index) in cartItems[0].cart_details" :key="index">
                <div class="row" v-for="(cartProd,cprodindex) in cartdetail.CartProduct" :key="cprodindex">
                  <div data-label="PRODUCT" class="col col-2">
                    <div class="name">
                      <img v-if="cartProd['product'].image[0] && cartProd['product'].image[0].url" :src="getMediaURL(cartProd['product'].image[0], 'small')"/>
                      <br style="clear: both;">
                      <div align="center" style="display: block; block; width: 100%;"><span v-if="cartProd['product'].Name">{{ cartProd['product'].Name }}</span></div>
                    </div>
                  </div>
                  <div v-if="cartProd['product'].CubeFt" data-label="CUBE FT" class="col" style="margin-left:10px">{{ cartProd['product'].CubeFt }}</div> 
                  <div v-if="cartProd['product'].Price" data-label="PRICE" class="col">{{ `$${cartProd['Price'].toFixed(2)}` }}</div> 
                  <div data-label="QUANTITY" class="col">
                      <div class="quantity">
                        <span class="pointer" @click="addProduct(cprodindex, cartProd['product'].Price,cartProd['product'].Quantity)">+</span>
                        <span v-if="cartProd['Quantity']">{{ cartProd['Quantity'] }}</span>
                        <span class="pointer" @click="removeProduct(cprodindex, cartProd['product'].Price)">-</span>
                      </div>
                  </div>
                  <div data-label="SUBTOTAL" class="col subtotal">
                    <span v-if="cartProd['Price']">{{ `$${ (cartProd['Price'] * cartProd['Quantity']).toFixed(2) }` }}</span>
                    <span @click="removeItems(cprodindex,cartProd['product'].Price)" class="close-btn pointer">
                      <img src="@/assets/icons/error-black.svg">
                    </span>
                  </div>
                </div> 
              </div>
            </div>
            <footer>
              <div class="update-cart">
                <!--<input type="text" disabled placeholder="Continue Shopping"> -->
                 <router-link tag="li" style="cursor:pointer" :to="{ name : 'Products' }">Continue Shopping</router-link>
                <button @click="updateToCart(true)" style="visibility: hidden;" class="pointer">UPDATE CART</button>
              </div>
            </footer>
          </section>
          <section>
            <header>
              <div class="col">
                CART TOTALS
              </div>
            </header>
            <div class="column">
             <div class="column__wrap">
                <h4>Volume</h4>
                <div class="value">
                  <p>{{ `${getVolume} Cube ft.` }}</p>
                 <!-- <div class="value-wrap mt-30">
                    <div class="inner-value">
                      <input type="radio" name="type" id="free">
                      <label for="free" class="pointer">Free Shipping</label>
                    </div>
                    <div class="inner-value">
                      <input type="radio" name="type" id="flat" >
                      <label for="flat" class="pointer">Flat Rate</label>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="column__wrap">
                <h4>Subtotal</h4>
                <div class="value">
                  <p>{{ `$${getTotal}` }}</p>
                  <!--<div class="value-wrap mt-30">
                    <div class="inner-value">
                      <input type="radio" name="type" id="free">
                      <label for="free" class="pointer">Free Shipping</label>
                    </div>
                    <div class="inner-value">
                      <input type="radio" name="type" id="flat" >
                      <label for="flat" class="pointer">Flat Rate</label>
                    </div>
                  </div>-->
                </div>
              </div>
              <div class="column__wrap">
                <h4>GST (5%)</h4>
                <div class="value">
                  <p>{{ `$${getGST}` }}</p>
                  <!--<div class="value-wrap mt-30">
                    <div class="inner-value">
                      <input type="radio" name="type" id="free">
                      <label for="free" class="pointer">Free Shipping</label>
                    </div>
                    <div class="inner-value">
                      <input type="radio" name="type" id="flat" >
                      <label for="flat" class="pointer">Flat Rate</label>
                    </div>
                  </div>-->
                </div>
              </div>
              <div class="input-checkbox">
                <input v-model="terms_condition" type="checkbox" id="terms_condition" name="terms_condition" value="terms_condition" class="pointer">
                <label for="terms_condition">Terms & conditions apply (Tax to be applied applicable)</label>
              </div>
              <div class="column__wrap">
                <h4>Shipping</h4>
                <div class="value">
                  <div class="value-wrap">
                    <div class="inner-value">
                      <label>Shipping option will be updated during checkout.</label>
                    </div>
                    <div class="inner-value">
                      <a>Calculate shipping</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column__wrap">
                <h4>Total</h4>
                <h3 class="total">{{ `$${getTotalWithGST}` }}</h3>
              </div>
              <!-- <button @click="terms_condition && isValid ? isCheckout = true : onAlert()" class="proceed-btn"> -->
                <button @click="onAlert()" class="proceed-btn">
                PROCEED TO CHECKOUT
                <img src="@/assets/icons/white-right-arrow.svg">
              </button>
            </div>
          </section>
        </div>
        <div class="cart-section" v-if="!getCartStatus">
         <div align="center" style="width: 100%;margin-top:50px;font-weight:bold;color: red !important;"><span> Your cart is empty!</span></div>
        </div>
        <div id="divId"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import getMediaURL from '@/mixins/getMediaURL'
import Tabs from '@/components/molecules/Tabs'
import Message from '@/components/molecules/Message'
import MHSelectbox from '@/components/molecules/MHSelectbox'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
   mixins: [getMediaURL],
  components: {
    Tabs,
    Message,
    MHSelectbox,
    DatePicker
  },
  data () {
    return {
      tabs: ['SHOPPING CART'],
      cartHeaders: ['PRODUCT', 'CUBE FT', 'PRICE', 'QUANTITY', 'SUBTOTAL'],
      shipVia: 'Select any field',
      shipViaID: null,
      isCheckout: false,
      terms_condition: false,
      isValid: false,
      errors: {},
      options: [
        {
          id: 1,
          value: 'Customer pickup/Carrier'
        },
        {
          id: 2,
          value: 'Prepaid and charges'
        }
      ],
      isHidden: true,
      updatePress: false,
      alertMessage: 'Are you sure want to proceed ?',
      emailAddressRegex: /\S+@\S+\.\S+/,
      regForm: {
        order: '',
        po: '',
        shipdate: '',
        shipvia: '',
        shipviaPicup: '',
        soldTo: '',
        dealerEmail: '',
        billTo: '',
        shipTo:''
      },
      credit_info: ''
    }
  },
  metaInfo() {
    return {
      title: 'View Cart'
    }
  },
  computed: {
     ...mapGetters('auth', ['userId']),
     ...mapState('products', ['cartItems','cartItemCount']),
     ...mapState('order', ['cartInfo']),
     ...mapState('auth', ['user', 'userDetails', 'creditLimit']), // data get from userDetails
     getRandomNumber: function () {
      const number = 8
      let result = Math.floor(Math.random() * Math.pow(10, number))
      return (result.toString().length < number) ? this.getRandomNumber : result
    },
    getTotal: function () {
      let sum = 0
      if (this.cartItems[0]) {
        sum = this.cartItems[0].cart_details.map(o => o.subtotal).reduce((a, c) => { return a + c })
      }
      return sum.toFixed(2)
    },
    getTotalQty: function () {
      let sum = 0
      if (this.cartItems[0]) {
        this.cartItems[0].cart_details[0].CartProduct.forEach((item) => {
          sum = sum + item.Quantity
         })
         return sum.toFixed(2)
      }
      return sum.toFixed(2)
    },
     getTotalWithGST: function () {
      let sum = 0
      let gst = 0
      if (this.cartItems[0]) {
        sum = this.cartItems[0].cart_details.map(o => o.subtotal).reduce((a, c) => { return a + c })
        gst = (sum/100) * 5;
        return (sum + gst).toFixed(2);
      }
      return sum.toFixed(2)
    },
     getVolume: function () {
      let sum = 0
      if (this.cartItems[0]) {
        this.cartItems[0].cart_details[0].CartProduct.forEach((item) => {
          sum = sum + item.product['CubeFt'] * item.Quantity
         })
         return sum.toFixed(2)
      }
      return sum
    },
    getGST: function () {
      let sum = 0
      if (this.cartItems[0]) {
        sum = this.cartItems[0].cart_details.map(o => o.subtotal).reduce((a, c) => { return a + c })
        sum = (sum/100) * 5;
        return sum.toFixed(2)
      }
      return sum.toFixed(2)
    },
    getCartStatus: function () {
      if (this.cartItems[0]) {
        if(this.cartItems[0].cart_details[0].CartProduct.length == 0) {
          return false
        } else {
          return true
        }
      } else{
        return true
      }
    }
  },
  watch: {
    userDetails: function () {
      if (this.userDetails && this.userDetails.personal_detail) {
        this.getCartInfo()
        this.getPersonalInfo()
        this.getCreditLimit()
      }
    },
    cartInfo: function () {
      if (this.cartInfo) {
        this.getCartInfoDetails()
      }
    }
  },
  created () {
    this.$store.dispatch('products/getCartList', 0)
    if (this.userDetails && this.userDetails.personal_detail) {
      this.getCartInfo()
    }
  },
  methods: {
    getCartInfo() {
      this.$store.dispatch('order/getCartInfo', {id: this.userDetails.personal_detail})
    },
    getPersonalInfo(){
      this.$store.dispatch('order/getCartInfo', {id: this.userDetails.personal_detail})
    },
    getCreditLimit(){
      this.$store.dispatch('auth/getCreditLimit', {id: this.userDetails.personal_detail})
    },
    selectShipVia (event) {
      this.shipVia = event.value
      this.shipViaID = event.id
      this.regForm['shipvia'] = this.shipVia
      if(event.id==1)
        this.isHidden= false;
      else
        this.isHidden= true;
    },
    removeItems (index,price) {
      this.cartItems[0].cart_details[0].subtotal = this.cartItems[0].cart_details[0].subtotal- (price * this.cartItems[0].cart_details[0].CartProduct[index].Quantity) 
      this.cartItems[0].cart_details[0].CartProduct.splice(index, 1)
      this.updateToCart(true)
    },
    addProduct (index, price,qty) {
      if (this.cartItems[0].cart_details[0].CartProduct[index].Quantity >= 1 && qty > this.cartItems[0].cart_details[0].CartProduct[index].Quantity) {
        this.cartItems[0].cart_details[0].CartProduct[index].Quantity++
        this.cartItems[0].cart_details[0].subtotal = this.cartItems[0].cart_details[0].subtotal + price
        this.updateToCart(true)
      }
      else{
        this.$store.commit('products/setNotification', { type: 'cart', method: 'warn', message: 'Quantity should be less then available Qty!'})
      }
    },
    removeProduct (index, price) {
      if (this.cartItems[0].cart_details[0].CartProduct[index].Quantity > 1) {
        this.cartItems[0].cart_details[0].CartProduct[index].Quantity--
        this.cartItems[0].cart_details[0].subtotal = this.cartItems[0].cart_details[0].subtotal- price 
        this.updateToCart(true)
      }
    },
    updateToCart(isDetails) {
      if (this.cartItems[0].cart_details != null) {
        this.cartItems[0].users_permissions_user = window.localStorage.getItem('userID')
        this.$store.dispatch('products/updateCart', this.cartItems[0])
      }
    },
    getCartInfoDetails() {
      if (this.cartInfo) {
        this.regForm['order'] = ""
        this.regForm['po'] = ""
        this.regForm['shipdate'] = ""
        this.regForm['shipvia'] = ""
        this.regForm['soldTo'] = this.cartInfo && this.cartInfo.EIN_proof[0] ? this.cartInfo.EIN_proof[0].name : ""
        this.regForm['dealerEmail'] = this.cartInfo && this.cartInfo.users_permissions_user ? this.cartInfo.users_permissions_user.email : ""
        this.regForm['billTo'] = this.cartInfo && this.cartInfo.EIN_proof[0] ? this.cartInfo.EIN_proof[0].Address : ""
        this.regForm['shipTo'] = this.cartInfo ? this.cartInfo.address : ""
      }
    },
    continueShoping () {
      this.$router.push({name: 'Products'})
      this.$emit('close')
    },
    onAlert() {
      if (!this.validateFields()) {
        return false;
      }
      else if(!this.terms_condition){
        this.$store.commit('products/setNotification', { type: 'cart', method: 'warn', message: 'Please accept Terms and Conditions to continue!'})
        return false;
      }
      else if(this.creditLimit['Balance'] < this.getTotalWithGST)
      {
        this.$store.commit('products/setNotification', { type: 'cart', method: 'error', message: 'Amount should be less then Credit Limit!'})
        return false;
      }
      else
      {
        this.isCheckout = true
      }
    },
    onCheckout(event) {
        if (event === 'confirm') {
          var orderNo= this.getRandomNumber
          var htmlContent = "";
          if (this.cartItems[0]) {
              this.cartItems[0].cart_details[0].CartProduct.forEach((element, index) => {
                htmlContent = htmlContent +"<tr>"    
                htmlContent = htmlContent + "<td style='border:solid windowtext 1.0pt;border-top:none;padding:4.0pt 4.0pt 4.0pt 4.0pt;display:table-cell'><p class='MsoNormal' align='center' style='text-align:center'>"+ element.Quantity +"<u></u><u></u></p></td><td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:4.0pt 4.0pt 4.0pt 4.0pt;display:table-cell'><p class='MsoNormal'><b>"+ element['product'].SKU +"</b><br><span style='color:#505050'>"+ element['product'].Name +" </span><u></u><u></u></p></td><td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:4.0pt 4.0pt 4.0pt 4.0pt;display:table-cell'><p class='MsoNormal' align='right' style='text-align:right'>"+ element['product'].CubeFt * element.Quantity +"<u></u><u></u></p></td><td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:4.0pt 4.0pt 4.0pt 4.0pt;display:table-cell'><p class='MsoNormal' align='center' style='text-align:center'>$"+ element['product'].Price +"<u></u><u></u></p></td><td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:4.0pt 4.0pt 4.0pt 4.0pt;display:table-cell'><p class='MsoNormal' align='right' style='text-align:right'>$"+ element['product'].Price * element.Quantity+"<u></u><u></u></p></td>"
                htmlContent = htmlContent + "</tr>"
              });
            }
          const replacements = {
              username: this.userDetails ? this.userDetails.fullname : "",
              category: this.userDetails ? this.userDetails.fullname : "",
              orderDate: new Date().toLocaleString(),
              orderNumber: orderNo,
              poNumber: this.regForm.po,
              companyName: this.regForm.soldTo,
              billAddress: this.regForm.billTo,
              shipAddress: this.regForm.shipTo,
              shipVia: this.regForm.shipviaPicup ? this.regForm.shipviaPicup : this.regForm.shipvia ,
              shipDate: this.regForm.shipdate,
              contact: this.userDetails.phoneNumber ? this.userDetails.phoneNumber : "",
              totalQty: this.getTotalQty,
              totalCubes: this.getVolume,
              totalPrice: this.getTotal,
              itemGST: this.getGST,
              itemTotalWithGST: this.getTotalWithGST,
              items: htmlContent
          };
      
          if (this.userDetails) {
            const data = {
              to : this.userDetails.email,
              subject: "Order details",
              emailTemplate: 'Test details',
              data: replacements,
            }
            const orderDetails = {
              users_permissions_user: this.userId,
              OrderNumber: orderNo,
              PONumber: this.regForm.po,
              Price: this.getTotal,
              GST: this.getGST,
              SubTotal: this.getTotalWithGST,
              IsActive: true,
              Status: "Pending",
              ShipDate: this.regForm.shipdate,
              TotalCubFt: this.getVolume,
              order_product: this.cartItems[0].cart_details[0].CartProduct
            }

            const cartItemsRemove = {
            id: this.cartItems[0].id,
            users_permissions_user: this.userId,
            cart_details: [
              {
                price: 0,
                quantity: 0,
                subtotal: 0,
                CartProduct: []
              }] 
            }

            
            if(this.creditLimit){
              this.credit_info = {
                id: this.creditLimit['id'],
                details: {
                  AmountUsed: Number(this.creditLimit['AmountUsed']) + Number(this.getTotalWithGST),
                  Balance: Number(this.creditLimit['Balance']) - Number(this.getTotalWithGST)
                }
              }
            }
            
            this.$store.dispatch('products/updateCart', cartItemsRemove)
            this.$store.dispatch('order/addOrder', orderDetails)
            console.log(data)
            this.$store.dispatch('products/sendOrderEmail', data)
            this.$store.dispatch('products/getCartList', 0)
            
            if(this.credit_info){
              this.$store.dispatch('auth/updateCreditLimit', this.credit_info)
            }
        }
        else
        {
          this.isCheckout = false
          this.$store.commit('products/setNotification', { type: 'cart', method: 'error', message: 'Please fill your personal details before order!'})
        }
      }
      this.isCheckout = false
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date()
      const day = today.getDay()
      const dayGet = date.getDay()
      // var dayCount = 3
      // var skipDays = 1
      // switch (day) {
      //   case 0:
      //     dayCount = 3
      //     skipDays = 1
      //     break
      //   case 1:
      //     dayCount = 3
      //     skipDays = 1
      //     break
      //   case 2:
      //     dayCount = 5
      //      skipDays = 1
      //     break
      //   case 3:
      //     dayCount = 5
      //      skipDays = 1
      //     break
      //   case 4:
      //     dayCount = 3
      //      skipDays = 3
      //     break
      //   case 5:
      //     dayCount = 3
      //     skipDays = 3
      //     break
      //   case 6:
      //     dayCount = 3
      //     skipDays = 2
      // }
      // today.setDate(today.getDate() + skipDays)
      // var counter=date < today || date > new Date(today.getTime() + dayCount * 24 * 3600 * 1000)
      // if (!counter) {
        if(dayGet == 0 || dayGet == 6) {
          return true
        } else {
          return false
        }
      //}
      //return counter
    },
    validateFields () {
      this.errors = {}
      if (!this.regForm['po']) {
        this.$set(this.errors, 'po', 'required')
      }
      if (!this.regForm['shipdate']) {
        this.$set(this.errors, 'shipdate', 'required')
      }
      if (!this.regForm['soldTo']) {
        this.$set(this.errors, 'soldTo', 'required')
      }
      if (!this.regForm['dealerEmail']) {
        this.$set(this.errors, 'dealerEmail', 'required')
      } 
      else if (!this.emailAddressRegex.test(this.regForm['dealerEmail'])) {
        this.$set(this.errors, 'emailAddressRegex', 'required')
      }
      if (!this.regForm['billTo']) {
        this.$set(this.errors, 'billTo', 'required')
      }
      if (!this.regForm['shipTo']) {
        this.$set(this.errors, 'shipTo', 'required')
      }
      if (!this.regForm['shipvia']) {
        this.$set(this.errors, 'shipvia', 'required')
      }
      if (this.regForm['shipvia']) {
        if(this.regForm['shipvia'] == 1){
          if (!this.regForm['shipviaPicup']) {
          this.$set(this.errors, 'shipviaPicup', 'required')
        }
        }
      }

      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        this.isValid =true
        return true
      }
    },
  }
  
}
</script>

<style lang="sass" scoped>
@import '../sass/login.sass'
%text-props
  @include font-source('Poppins', 15px, 600, $black1)
%common-props
  display: flex
  flex-direction: column
  align-items: flex-start
%btn-props
  background: $black1
  color: #fefefe
  border: none
  @include font-source('Poppins', 12px, 600)
.tabs-bar
  padding: 50px 0 70px 0
.cart-wrapper, .cart-items
  display: flex
  flex-direction: column
  h3
    align-self: baseline
    padding-bottom: 70px
    @extend %text-props
    font-size: 24px
    &.cart-heading
      padding-bottom: 40px
.cart-wrapper
    margin-bottom: 12em
    .input-wrapper
      &.outer-block
        flex-direction: row
        flex-wrap: wrap
        margin-bottom: 15px
        @media( max-width: $md-breakpoint)
          flex-direction: column
        .input-container
          margin-right: 20px
          width: calc( 100% / 4 - 20px)
          @media( max-width: $ms-breakpoint)
            width: calc( 100% / 2 - 20px)
          @media( max-width: $md-breakpoint)
            width: 100%
            margin-right: 0
          &:last-child
            margin-right: 0
          label
            @extend %text-props
          .select-wrap
            flex: 1
          .input-field
            padding: 0 25px
          ::placeholder
            @extend %text-props
            font-weight: 400
            color: $text-grey
            font-size: 12px
        &.column-2
          .input-container
            width: calc( 100% / 2 - 20px)
            @media( max-width: $md-breakpoint)
              width: 100%
          .input-container, .input-container > textarea
            height: 7em
            resize: none
    .cart-section
      display: flex
      @media screen and (max-width: $ms-breakpoint)
        flex-direction: column
      section
        flex: 2.5
        border: 1px solid map-get($greyShades, 'greyShade4')
        &:nth-child(2)
          flex: 1
          height: fit-content
          @media screen and (min-width: $ms-breakpoint1)
            margin-left: 1.5em
          @media screen and (max-width: $ms-breakpoint1)
            margin-top: 1.5em
          header
            background: map-get($greyShades, 'greyShade3')
          .column
            padding: 1em 1em 2.5em 1em
            background: map-get($greyShades, 'greyShade1')
            &__wrap
              display: flex
              margin-bottom: 0.6em
              h4
                flex: 1
                display: flex
                @extend %text-props
              h3.total
                padding-bottom: 0 
              .value
                flex: 2
                @extend %common-props
                margin-left: 25px
                p
                  @extend %text-props
                  font-size: 20px
                .value-wrap
                  @extend %common-props
                  text-align: left
                  &.mt-30
                    margin-top: 30px
                  .inner-value
                    input[type="radio"]
                      padding: 0
                      width: 12px
                      height: 12px
                      border: 1px solid $text-grey
                      &:checked
                        border: 1px solid $black 
                    label, a
                      @include font-source('Poppins', 13px, 600, $text-grey)
                    a
                      color: $black1
                      text-decoration: underline
                      padding: 1em 0 1.5em 0
                      display: flex
            button
              &.proceed-btn
                @extend %btn-props
                height: 70px
                width: 100%
                margin-top: 16px
                position: relative
                cursor: pointer
                img
                  position: absolute
                  top: 32%
                  width: 24px
                  margin-left: 8px
      header
        @extend %text-props
      header, .row, footer
        display: flex
        padding: 12px 16px
        min-height: 50px
        align-items: center
        border-bottom: 1px solid map-get($greyShades, 'greyShade4')
      .col
        flex: 1
        display: flex
        @extend %text-props
        &.subtotal
          justify-content: space-between
          .close-btn
            img
              width: 25px
        &.col-2
          flex: 2
          .name
            span
              @include font-source('Poppins', 14px, 400, $text-grey)
            img
              width: 30%
              margin-right: 1em
        .name, .quantity
          display: flex
          align-items: center
        .quantity
          border: 1px solid map-get($greyShades, 'greyShade4')
          padding: 0 20px 0 10px
          span
            padding: 8px 12px
            &:first-child, &:nth-child(3)
              @include font-source('Poppins', 16px, 400, $text-grey)
      footer
        border-bottom: none
        .coupin
          margin-right: 1em
        .update-cart
          flex: 1
          display: flex
          align-items: center
          justify-content: space-between
          input
            height: 3.7em
            padding: 0 25px
            background: transparent
            border: 1px solid map-get($greyShades, 'greyShade2')
            flex: 2
            margin-right: 1em
            &:focus, &:active
              outline: none
          button
            flex: 1
            align-self: stretch
            @extend %btn-props
        .update-cart
          input
            border: none
          ::placeholder
            @extend %text-props
.input-checkbox
  display: flex
  align-items: center
  margin-bottom: 0.6em
  input[type="checkbox"]
    width: 20px
    height: 20px
  label
    text-align: left
    margin-left: 15px
    @include font-source('Poppins', 13px, 600, $text-grey)
@media screen and (max-width: $md-breakpoint)
  header
    display: none !important
  .row
    flex-direction: column
    align-items: unset !important
    position: relative
    .col
      align-items: center
      padding: 20px 0
      justify-content: space-between
      &:before
        content: attr(data-label)
      &.col-2
        .name
          flex-direction: column
          align-items: flex-end
      &.subtotal
        .close-btn
          position: absolute
          top: 10px
          right: 10px
  .update-cart, footer
    flex-direction: column
    input
      flex: unset !important
    button
      flex: unset !important
      align-self: unset !important
      height: 50px
      margin-top: 15px


</style>
<style lang="sass">
.input-container
  width: calc( 100% / 4 - 20px)
  .mx-datepicker
    width: 100%
    .mx-input-wrapper
      width: 100%
      .mx-input 
        display: inline-block
        -webkit-box-sizing: border-box
        box-sizing: border-box
        height: 55px
        padding: 6px 30px
        padding-left: 10px
        font-size: 14px
        line-height: 1.4
        color: #555
        background-color: #fff
        border: 1px solid #ccc
        border-radius: 4px
      .mx-icon-calendar, .mx-icon-clear
        position: absolute
        top: 50%
        -webkit-transform: translateY(-50%)
        transform: translateY(-50%)
        font-size: 16px
        line-height: 1
        color: rgba(0,0,0,.5)
        vertical-align: middle

</style>